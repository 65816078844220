import * as holiday_jp from "@holiday-jp/holiday_jp"
import {
  FeeTable,
  FeeUnit,
  Parking,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { FeeUnitExtension } from "../../domain/parking-extensions"
import { UseCase } from "./use-case"

interface Props {
  parking: Parking
  date: Date
}

export class GetParkingCurrentFeeUseCase
  implements UseCase<Props, FeeUnit | null>
{
  call({ parking, date }: Props): FeeUnit | null {
    const feeTable = parking.feeTable
    if (!feeTable) {
      return null
    }

    const feeUnit = this.currentFeeUnit(feeTable, date)
    if (!feeUnit) {
      return null
    }
    return feeUnit
  }

  currentFeeUnit(ft: FeeTable, date: Date): FeeUnit | undefined {
    return this.feeUnitsOfToday(ft, date).find(u =>
      FeeUnitExtension.includes(u, date)
    )
  }

  feeUnitsOfToday(ft: FeeTable, date: Date): FeeUnit[] {
    const isHoliday = holiday_jp.isHoliday(date)
    if (isHoliday) {
      return ft.holidayFees // Holiday
    }

    switch (date.getDay()) {
      case 0: // Sunday
        return ft.sundayFees
      case 1: // Monday
      case 2: // Tuesday
      case 3: // Wednesday
      case 4: // Thursday
      case 5: // Friday
        return ft.weekdayFees
      case 6: // Saturday
        return ft.saturdayFees

      default:
        return [] // unexpected error
    }
  }
}
