import {
  ReserveRequest,
  ReserveResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { ParkingServiceType } from "../services"
import { UseCase } from "./use-case"

export class ReserveUseCase
  implements UseCase<ReserveRequest, Promise<ReserveResponse>>
{
  constructor(readonly parkingService: ParkingServiceType) {}

  async call(request: ReserveRequest): Promise<ReserveResponse> {
    try {
      const result = await this.parkingService.reserve(request)
      if (result.isFailure()) {
        console.error(result.value)
        throw new Error("Reservation failed")
      }
      return result.value
    } catch (error) {
      console.error("Error during reservation:", error)
      throw new Error("Error during reservation")
    }
  }
}
