import { navigate } from "gatsby"
import React, { ReactElement, useState } from "react"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { Transaction } from "../../__generated__/proto/itech/motorist/pksha/v1/transaction"
import { ParkingService } from "../../domain/services"
import { ReserveUseCase } from "../../domain/use-cases"
import { getLatestReservedEvent } from "../../hooks/get-latest-transaction"
import { generateUUID } from "../../utils/uuid"
import * as styles from "./parking-reservation-form.module.scss"

const parkingService = new ParkingService()
const reserveUseCase = new ReserveUseCase(parkingService)

const SpaceNumberInputRow = ({
  value,
  onChange,
  error,
  onValidate,
}: {
  value: string
  onChange: (value: string) => void
  error?: string
  onValidate: (error: string | null) => void
}): ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    onChange(newValue)
    onValidate(validateSpaceNumber(newValue))
  }

  const validateSpaceNumber = (number: string): string | null => {
    if (number === "") return null
    if (!/^\d+$/.test(number)) return "車室番号は整数でなければなりません。"
    return null
  }
  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}>
        車室
        <br />
        (オプション)
      </div>
      <div className={styles.infoValueSection}>
        <input
          type="text"
          className={styles.spaceNumberInput}
          placeholder="車室番号"
          value={value}
          onChange={handleChange}
        />
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.notice}>
          車室をご指定された場合、他の車室のご利用はできません。
        </div>
      </div>
    </div>
  )
}

const LicenseNumberInputRow = ({
  value,
  onChange,
  error,
  onValidate,
}: {
  value: string
  onChange: (value: string) => void
  error?: string
  onValidate: (error: string | null) => void
}): ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    onChange(newValue)
    onValidate(validateLicenseNumber(newValue))
  }

  const validateLicenseNumber = (number: string): string | null => {
    if (number === "") return "車両ナンバーを入力してください。"
    if (!/^\d+$/.test(number)) return "車両ナンバーは整数でなければなりません。"
    return null
  }

  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}>ナンバー</div>
      <div className={styles.infoValueSection}>
        <input
          type="text"
          className={styles.licenseNumberInput}
          placeholder="車両ナンバー"
          value={value}
          onChange={handleChange}
        />
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.notice}>
          駐車車両とご予約者の確認のために、4桁の車両ナンバー(数字のみ)の入力をお願いします。
        </div>
      </div>
    </div>
  )
}

const RequireHandicappedSpaceCheckboxRow = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}): ReactElement => {
  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}></div>
      <div className={styles.infoValueSection}>
        <div className={styles.checkboxRow}>
          <input
            type="checkbox"
            id="requireHandicappedSpace"
            checked={checked}
            onChange={onChange}
          />
          <label htmlFor="requireHandicappedSpace">
            車椅子対応車室を優先して予約する
          </label>
        </div>
      </div>
    </div>
  )
}

interface Props {
  parking: Parking
}
export const ParkingReservationForm = React.memo(
  function ParkingReservationInfoTable(props: Props): ReactElement {
    const [spaceNumber, setSpaceNumber] = useState("")
    const [licenseNumber, setLicenseNumber] = useState("")
    const [requireHandicappedSpace, setRequireHandicappedSpace] =
      useState(false)
    const [errors, setErrors] = useState<{
      spaceNumber?: string
      licenseNumber?: string
    }>({})

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const requestId = generateUUID()

      if (errors.spaceNumber || errors.licenseNumber) {
        return
      }

      const paddedSpaceNumber = spaceNumber ? spaceNumber.padStart(3, "0") : ""
      const paddedLicenseNumber = licenseNumber.padStart(4, "0")

      reserveUseCase
        .call({
          requestId: requestId,
          parkingSpaceName: `${props.parking.name}/spaces/${paddedSpaceNumber}`,
          licenseNumber: paddedLicenseNumber,
          requireHandicappedSpace: requireHandicappedSpace,
        })
        .then(response => {
          const transaction = response?.transaction as Transaction
          const reservationTime =
            getLatestReservedEvent(transaction)?.createTime

          alert(
            `予約が完了しました。\n 予約時間: ${
              reservationTime && reservationTime.toLocaleString()
            }`
          )
          navigate(`/`)
        })
        .catch(e => {
          console.error(e)
          alert(
            "予約エラー：車室に空きがなく、予約することができませんでした。\n" +
              "駐車場検索画面から空き状況を確認いただき、再度ご予約ください。"
          )
          navigate(`/`)
        })
    }
    return (
      <form className={styles.container} onSubmit={handleSubmit}>
        <SpaceNumberInputRow
          value={spaceNumber}
          onChange={value => {
            setSpaceNumber(value)
          }}
          error={errors.spaceNumber}
          onValidate={error =>
            setErrors({ ...errors, spaceNumber: error ?? undefined })
          }
        />
        <LicenseNumberInputRow
          value={licenseNumber}
          onChange={value => {
            setLicenseNumber(value)
          }}
          error={errors.licenseNumber}
          onValidate={error =>
            setErrors({ ...errors, licenseNumber: error ?? undefined })
          }
        />
        <RequireHandicappedSpaceCheckboxRow
          checked={requireHandicappedSpace}
          onChange={e => setRequireHandicappedSpace(e.target.checked)}
        />
        <p className={styles.importantNoticeText}>
          ※ご予約を開始された後、予約完了画面を必ずご確認ください。
        </p>
        <div className={styles.buttonRow}>
          <button type="submit">予約する</button>
        </div>
      </form>
    )
  }
)
