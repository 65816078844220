import platform from "platform"
import React, { ReactElement } from "react"
import { Parking } from "../../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../../../config"
import * as styles from "./parking-detail-info-row.module.scss"

export const AvailableServicesInfoRow = ({
  parking,
}: {
  parking: Parking
}): ReactElement => {
  const appURL =
    platform.os?.family === "iOS"
      ? config.QT_NET_APP_IOS_URL
      : platform.os?.family === "Android"
      ? config.QT_NET_APP_ANDROID_URL
      : null

  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}>利用可能なサービス</div>
      <div className={styles.infoValueSection}>
        <div className={styles.servicesText}>
          {parking.isQtNetAvailable && <p>QT-net ポイント貯まる</p>}
          {parking.isQtNetAvailable && parking.isSmartPayAvailable && (
            <p>QT-net SmartPay利用可能</p>
          )}
          {parking.isQtNetAvailable && parking.reservable && (
            <p>QT-net スマホ予約可能</p>
          )}
        </div>
        {appURL && (
          <a
            className={styles.downloadQtNetButton}
            href={appURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            QT-netアプリをダウンロード
          </a>
        )}
      </div>
    </div>
  )
}
