import {
  Transaction,
  Transaction_Event_Reserved,
} from "../__generated__/proto/itech/motorist/pksha/v1/transaction"

export function getLatestReservedEvent(
  transaction: Transaction
): Transaction_Event_Reserved | undefined {
  const reservedEvents = transaction.events
    .filter(event => event.reserved !== undefined)
    .map(event => event.reserved)

  return reservedEvents[0]
}
