// extracted by mini-css-extract-plugin
export var container = "parking-reservation-form-module--container--1Y-QF";
export var infoRow = "parking-reservation-form-module--infoRow--1LbMF";
export var infoKeySection = "parking-reservation-form-module--infoKeySection--36Fvx";
export var infoValueSection = "parking-reservation-form-module--infoValueSection--3TjZA";
export var spaceNumberInput = "parking-reservation-form-module--spaceNumberInput--22q_Z";
export var licenseNumberInput = "parking-reservation-form-module--licenseNumberInput--3ttcg";
export var notice = "parking-reservation-form-module--notice--3pxNi";
export var checkboxRow = "parking-reservation-form-module--checkboxRow--1ekZr";
export var importantNoticeText = "parking-reservation-form-module--importantNoticeText--3Ta4D";
export var buttonRow = "parking-reservation-form-module--buttonRow--4Div5";
export var error = "parking-reservation-form-module--error--39BwT";