import { Link, navigate } from "gatsby"
import { useAtom } from "jotai"
import React, { ReactElement, useEffect, useState, useCallback } from "react"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { parkingNameAtom } from "../../atoms/atom"
import * as config from "../../config"
import { useAuth } from "../../middleware/AuthGuard"
import * as styles from "./header.module.scss"

const logoPath = "/QT-net.webp"

interface HeaderProps {
  currentParking?: Parking
}

export const Header = ({ currentParking }: HeaderProps): ReactElement => {
  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })
  // MARK: - States
  const [, setParkingName] = useAtom(parkingNameAtom)

  const [isScrolled, setIsScrolled] = useState(false)
  const { user, logout } = useAuth()

  // MARK: - Effects
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const scrollThreshold = isMobile ? 80 : 170
        setIsScrolled(window.scrollY > scrollThreshold)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [isMobile])

  const handleReservationClick = useCallback(() => {
    if (!user) {
      setParkingName(currentParking?.name || null)
      navigate("/signin")
    } else {
      navigate(`/${currentParking?.name}/reservation`)
    }
  }, [currentParking, user])

  return (
    <header className={styles.header}>
      <div className={styles.row}>
        <div className={styles.leftSection}>
          <Link to="/" className={styles.logo}>
            <img
              src={logoPath}
              alt="logo"
              width={isMobile ? 48 : 77}
              height={isMobile ? 20 : 32}
            />
          </Link>
          <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
            <p className={styles.title}>駐車場検索</p>
          </MediaQuery>
        </div>
        <div className={styles.rightSection}>
          {/* todo: パスが決まったらリンク先を変更する */}
          {isScrolled && currentParking ? (
            <button
              onClick={handleReservationClick}
              className={styles.reservationPageButton}
            >
              予約手続きへ
            </button>
          ) : user ? (
            <>
              <p className={styles.userName}>{user.name}様</p>
              <button onClick={logout} className={styles.logoutButton}>
                ログアウト
              </button>
            </>
          ) : (
            <>
              <Link to="/signin" className={styles.authButton}>
                ログイン
              </Link>
              <Link
                to="https://qt-net.itcbb.net/signup"
                className={styles.authButton}
              >
                新規登録
              </Link>
            </>
          )}
        </div>
      </div>
      <MediaQuery maxWidth={config.RESPONSIVE_BREAKPOINT}>
        <div className={styles.bottomBorder} />
      </MediaQuery>
    </header>
  )
}
