import axios from "axios"
import { useAtom } from "jotai"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  GetParkingRequest,
  GetParkingResponse,
  ListParkingsRequest,
  ListParkingsResponse,
  ReserveRequest,
  ReserveResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms/atom"
import * as config from "../../config"
import { Failure, Result, Success } from "./result"
// MARK: - Interfaces

export interface ParkingServiceType {
  getParking(
    request: GetParkingRequest
  ): Promise<Result<Parking, GetParkingError>>
  listParkings(
    request: ListParkingsRequest
  ): Promise<Result<ListParkingsResponse, ListParkingsError>>
  reserve(
    request: ReserveRequest
  ): Promise<Result<ReserveResponse, ReserveError>>
}

// MARK: - Implements

export class ParkingService implements ParkingServiceType {
  async getParking(
    request: GetParkingRequest
  ): Promise<Result<Parking, GetParkingError>> {
    try {
      const response = await axios.post<GetParkingResponse>(
        `${config.PKSHA_HTTP_API_URL}/parking`,
        {
          headers: { "Content-Type": "application/json" },
          name: request.name,
        }
      )

      if (response.data.parking) {
        return new Success(response.data.parking)
      } else {
        return new Failure(new GetParkingError("Parking not found"))
      }
    } catch (error) {
      console.error(error)
      return new Failure(new GetParkingError())
    }
  }

  async listParkings(
    request: ListParkingsRequest
  ): Promise<Result<ListParkingsResponse, ListParkingsError>> {
    try {
      const response = await axios.post<ListParkingsResponse>(
        `${config.PKSHA_HTTP_API_URL}/parkings`,
        {
          headers: { "Content-Type": "application/json" },
          page_size: request.pageSize,
          page_token: request.pageToken,
          bounding_box: request.boundingBox,
        }
      )
      return new Success(response.data)
    } catch (error) {
      console.error(error)
      return new Failure(new ListParkingsError())
    }
  }

  async reserve(
    request: ReserveRequest
  ): Promise<Result<ReserveResponse, ReserveError>> {
    const [auth] = useAtom(authAtom)

    if (!auth) {
      return new Failure(new ReserveError("Authorization token is missing"))
    }

    try {
      const response = await axios.post<ReserveResponse>(
        `${config.PKSHA_HTTP_API_URL}/reserve`,
        {
          parking_space_name: request.parkingSpaceName,
          request_id: request.requestId,
          license_number: request.licenseNumber,
          required_handicapped_space: request.requireHandicappedSpace,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${auth}`,
          },
        }
      )
      return new Success(response.data)
    } catch (error) {
      console.error(error)
      return new Failure(new ReserveError())
    }
  }
}

// MARK: - Errors

export class GetParkingError extends Error {}
export class ListParkingsError extends Error {}
export class ReserveError extends Error {}
