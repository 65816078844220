// extracted by mini-css-extract-plugin
export var header = "header-module--header--rokHo";
export var row = "header-module--row--eEzR-";
export var leftSection = "header-module--leftSection--4eXYm";
export var logo = "header-module--logo--1pBRd";
export var commonTextStyle = "header-module--commonTextStyle--mY_Lc";
export var userName = "header-module--userName--39JOP";
export var authButton = "header-module--authButton--us34i";
export var logoutButton = "header-module--logoutButton--2rDYy";
export var title = "header-module--title--h9tnr";
export var rightSection = "header-module--rightSection--3ViIx";
export var reservationPageButton = "header-module--reservationPageButton--k-d2H";
export var bottomBorder = "header-module--bottomBorder--3H096";