import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { useAuth } from "../../middleware/AuthGuard"
import { SearchFilterType } from "../../types"
import * as styles from "./mobile-search-header.module.scss"
import { SearchFilterChip, SearchInputBar } from "."

const logoPath = "/QT-net.webp"

interface Props {
  searchFilters: (keyof typeof SearchFilterType)[]
  onSelectFilter: (type: keyof typeof SearchFilterType) => void
  onDeselectFilter: (type: keyof typeof SearchFilterType) => void
}

export const MobileSearchHeader = (props: Props): ReactElement => {
  const { user, logout } = useAuth()

  const searchFilterChip = (
    type: keyof typeof SearchFilterType
  ): ReactElement => (
    <SearchFilterChip
      type={type}
      isSelected={props.searchFilters.includes(type)}
      onSelect={props.onSelectFilter}
      onDeselect={props.onDeselectFilter}
    />
  )

  return (
    <header className={styles.header}>
      <div className={styles.upperRow}>
        <div className={styles.leftSection}>
          <Link to="/" className={styles.logo}>
            <img src={logoPath} alt="logo" width={48} height={20} />
          </Link>
        </div>
        <div className={styles.authButtons}>
          {user ? (
            <>
              <p className={styles.userName}>{user.name}様</p>
              <button onClick={logout} className={styles.logoutButton}>
                ログアウト
              </button>
            </>
          ) : (
            <>
              <Link to="/signin" className={styles.authButton}>
                ログイン
              </Link>
              <Link
                to="https://qt-net.itcbb.net/signup"
                className={styles.authButton}
              >
                新規登録
              </Link>
            </>
          )}
        </div>
      </div>
      <div className={styles.searchInput}>
        <SearchInputBar />
      </div>
      <div className={styles.lowerRow}>
        {searchFilterChip("AVAILABLE")}
        {searchFilterChip("QT_NET_RESEVABLE")}
        {searchFilterChip("QT_NET_POINT")}
        {searchFilterChip("QT_NET_SMART_PAY")}
        {searchFilterChip("WHEELCHAIR")}
      </div>
    </header>
  )
}
