// extracted by mini-css-extract-plugin
export var header = "mobile-search-header-module--header--2uUEw";
export var upperRow = "mobile-search-header-module--upperRow--1xzAm";
export var logo = "mobile-search-header-module--logo--2uftn";
export var leftSection = "mobile-search-header-module--leftSection--1TYws";
export var authButtons = "mobile-search-header-module--authButtons--1AINV";
export var authButton = "mobile-search-header-module--authButton--1bjO5";
export var logoutButton = "mobile-search-header-module--logoutButton--1QJXB";
export var userName = "mobile-search-header-module--userName--cyT-p";
export var searchInput = "mobile-search-header-module--searchInput--g1w9X";
export var lowerRow = "mobile-search-header-module--lowerRow--3k5WG";