import React, { ReactElement } from "react"
import { Region } from "../../domain/use-cases/get-regions-use-case"
import * as styles from "./area-selection-section.module.scss"
import { PrefectureSelectionView, TownSelectionView } from "."

export const AreaSelectionSection = React.memo(
  (props: {
    region: Region
    prefecture?: string
    city?: string
    towns?: string[]
    selectingCity: boolean
    selectingTown: boolean
  }): ReactElement => (
    <div id="areaSelectionSection">
      <h4 className={styles.sectionTitle}>
        {props.selectingCity
          ? `${props.prefecture}の地域から探す`
          : "地域選択で探す"}
      </h4>

      {props.selectingTown ? (
        <TownSelectionView
          prefecture={props.prefecture ?? ""}
          city={props.city ?? ""}
          towns={props.towns ?? []}
        />
      ) : (
        <PrefectureSelectionView region={props.region} />
      )}
    </div>
  )
)
